.formContainer {
    margin-top: 10px;
    margin-bottom: 30px;
    margin-left: 40px;
    width: 90%;
    min-height: 380px;
  }

  .propertyContainer {
      margin-left: 40px;
      display: flex;
      align-items: center;
  }

  .entryPropertyContainer {
    margin-top: 5px;  
    display: flex;
    align-items: center;
  }

  .formContent {
      margin-top: 10px;
      border: 2px solid #ccc;
  }

  .selectFormControl.MuiFormControl-root {
      min-width: 180px;
      margin-left: 80px;
  }

  .selectPropertyFormControl.MuiFormControl-root {
    min-width: 180px;
}

.selectScheduleFormControl.MuiFormControl-root {
    min-width: 46%;
}


  .selectInputLabel.MuiFormLabel-root {
      padding-left: 5px;
  }

  .createField {
      display: flex;
      align-items: center;
      margin: 5px;
      border: 2px solid #e5e5e5;
      padding-left: 10px;
  }

  .propertyEntryField {
    display: flex;
    flex-direction: column;
    margin: 5px;
    padding-left: 10px;
  }

  .enterField {
    display: flex;
    margin: 5px;
    border: 2px solid #757474;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.selectField {
    align-items: baseline;
}

.enterSection {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
}

  .createLabel.MuiFormControl-root {
      margin-top: 5px;
      margin-bottom: 5px;
      min-width: 200px;
  }

  .createItem, .createItem.MuiFormControl-root {  
    margin: 5px;
    margin-left: 80px;
  }

  .requiredItem .MuiFormLabel-root {  
    color: red;
  }

  .enterItem, .enterItem.MuiFormControl-root {  
    margin: 5px;
    min-width: 45%;
  }

    .hidefield.MuiFormControl-root {  
        display: none;
    }

  .actionButton {
    margin-left: auto;
    margin-right: 5px;
}

.sectionPropertiesToolbar {
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.toolbarButton {
    margin-left: 10px;
}

.subLabelContainer {
    margin-left: 10px;
}

.subLabelItem {
    margin-left: 20px;
    display: flex;
    align-items: center;
}

.addButton {
    margin-left: 20px;
}

.sectionTitle {
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.entryunit, .entryField.MuiFormControl-root {
    margin-left: 40px;
}

.sublabelFields {
    margin-left: 40px;
}

.sublabelHeaderContainer {
    display: flex;
    flex-direction: row;
}

.sublabelColumn {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}

.saveButton.MuiButtonBase-root {
    margin-left: 10px;
    margin-top: 10px;
  }




  