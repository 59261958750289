@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,500,600,700);
@import url(https://weloveiconfonts.com/api/?family=fontawesome);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  display: flex;
  width: 100%;
  align-items: stretch;
}

body {
  /*font-family: 'Poppins', sans-serif;*/
  font-family: 'Nunito Sans', sans-serif;
  /*background: #fafafa;*/
  background-color: #F4F6F9;
}


a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
  min-width: 190px;
  max-width: 190px;
  background: #354052;
  color: #adb5bd;
  margin-left: -190px;
  transition: all 0.5s;
}

.sidebar.is-open {
  margin-left: 0;
  transition: .5s;
}


.sidebar-header {
  background: #354052;
  color: #adb5bd;
}

.sidebar-header h3{
  color: #fff;
  padding: 1em;
}

.sidebar ul p {
  color: #fff;
  padding: 10px;
}

.collapse.show, 
.collapsing {
  background: #313b4c;
}

.navbar{
  margin: 0 -15px;
}

.nav-item {
  /*color: #ced4da;*/
  color: #adb5bd;
  font-weight: 300;
}

.nav-item.open {
  color: #e9ecef;
  background: #313b4c;
}

.nav-item.active {
  color: #e9ecef;
  background: #2d3646 !important;
}

.nav-item:hover {
  background: #313b4c;
  color: #fff;
  transition: .2s;
}

.nav-item .svg-inline--fa {
  color: inherit;
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%
}

.sidebar-header > button {
  position: relative;
  float: right;
  margin: .5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}


/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.content {
  /*padding: 20px;*/
  margin-left: 0;
  height: 100vh;
}

.content-screen {
  margin-top: 15px;
  display: block;
  float: left;
  text-align: left;  
  background: #fff;
  overflow: scroll;
  
  height: 100%;
}

/* @media only screen and (max-width: 575.98px) {
  body {
    overflow:  hidden;
  }
  
  .content.is-open {
    display: none;
    transition: all 0.5s;
  }
  
  .sidebar.is-open {
    min-width: 100%;
    max-width: 100%;
    margin-left: 0;
    transition: all 0.5s, height 0s;
    height: 100vh !important;
  }
  
  .sidebar.is-open > .sidebar-header button {
    display: block;
  }
  
  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 68%;
  }
} */

.textgroup {
  position: relative;
  margin-top: 6px;
  margin-bottom: 24px;
  
}
.textunderline {
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid #DCDCDC;
}

.textlabel {
  color: #999;
  font-size: 12px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  
  top: -15px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.block {
display: block
}

.medium {
  border-radius: 3px;
  background: #FFCC00;
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
  width: 52px;
  height: 18px; 
  color: black;
  font-size: 12px;
}

.low {
  border-radius: 3px;
  background: #0099FF;
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
  width: 52px;
  height: 18px; 
  color: white;
  font-size: 12px;
}

.high {
  border-radius: 3px;
  background: #CC3300;
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
  width: 52px;
  height: 18px; 
  color: white;
  font-size: 12px;
}

[class*="fontawesome-"]:before {
    font-family: 'fontawesome', sans-serif;
}

.select-search-box {
  width: 300px;
  position: relative;
  margin-bottom: 20px;
  background: #fff;
  border-radius: 4px;
}

  .select-search-box--multiple {
      border-radius: 4px;
      box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
      overflow: scroll;
  }

  .select-search-box::after {
      font-family: fontawesome;
      content:"\f078";
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      text-align: center;
      line-height: 50px;
      color: #222f3e;
      z-index: 1;
  }

  .select-search-box--multiple::after, .select-search-box--focus::after {
      content:"\f002";
  }
  
  .select-search-box__icon--disabled::after {
      content: none !important;
  }

  .select-search-box--input::after {
      display: none !important;
  }

  .select-search-box__out {
      display: none;
  }

  .select-search-box__search {
      display: block;
      width: 100%;
      height: 50px;
      border: none;
      background: none;
      outline: none;
      font-size: 16px;
      padding: 0 20px;
      color: #222f3e;
      -webkit-appearance: none;
      box-sizing: border-box;
      position: relative;
      z-index: 2;
      cursor: pointer;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      line-height: 50px;
      box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  }

  input.select-search-box__search {
      line-height: 1;
  }

  .select-search-box--multiple .select-search-box__search {
      box-shadow: none;
  }

  .select-search-box--input .select-search-box__search {
      cursor: text;
  }

      .select-search-box__search:focus {
          cursor: text;
      }

      .select-search-box__search--placeholder {
          font-style: italic;
          font-weight: normal;
      }

  .select-search-box input::-webkit-input-placeholder {
      color: #ccc;
      font-style: italic;
      font-weight: normal;
  }

  .select-search-box input::-moz-placeholder {
      color: #ccc;
      font-style: italic;
      font-weight: normal;
  }

  .select-search-box input:-moz-placeholder {
      color: #ccc;
      font-style: italic;
      font-weight: normal;
  }

  .select-search-box input:-ms-input-placeholder {
      color: #ccc;
      font-style: italic;
      font-weight: normal;
  }

  input[type='search']::-webkit-search-cancel-button, input[type='search']::-webkit-search-decoration {
      -webkit-appearance: none;
  }

  .select-search-box__select {
      display: none;
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      background: #fff;
      border-radius: 4px;
      overflow: scroll;
      box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
      z-index: 100;
      min-height: 49px;
  }

  .select-search-box--multiple .select-search-box__select {
      display: block;
      position: static;
      border-top: 1px solid #eee;
      border-radius: 0;
      box-shadow: none;
  }

      .select-search-box__select--display {
          display: block;
      }

  .select-search-box__option {
      font-size: 16px;
      font-weight: 400;
      color: #616b74;
      padding: 15px 20px;
      border-top: 1px solid #eee;
      cursor: pointer;
      white-space: nowrap;
      overflow: scroll;
      text-overflow: ellipsis;
      -webkit-user-select: none;
          -ms-user-select: none;
              user-select: none;
  }

      .select-search-box__option:first-child {
          border-top: none;
      }

      .select-search-box__option--hover, .select-search-box__option:hover {
          background: #f4f7fa;
      }

      .select-search-box__option--selected {
          background: #54A0FF;
          color: #fff;
          border-top-color: #2184ff;
      }

          .select-search-box__option--selected:hover, .select-search-box__option--selected.select-search-box__option--hover {
              background: #2184ff;
              color: #fff;
              border-top-color: #2184ff;
          }

      .select-search-box__group {
          margin-top: 20px;
          padding-top: 20px;
          border-top: 1px solid #eee;
          position: relative;
      }

      .select-search-box__group-header {
          position: absolute;
          top: 0;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          background: white;
          padding: 0 10px;
          color: rgba(0, 0, 0, 0.5);
          font-size: 12px;
      }

      .login-form {
        width: 100%;
        max-width: 450px;
        padding: 20px;
        margin: auto;
        
        background: white;
        border: solid 1px rgb(193, 206, 230);

        position: absolute;
      
        top: 50%;
        left: 20px;
        right: 20px;
        
        
        
        -webkit-transform: translateY(-50%);
        
        
        
                transform: translateY(-50%);
        resize: vertical;
        overflow: auto;

      }

    .img-wrap {
        position: relative;
        display: inline-block;        
        font-size: 0;
    }
    .img-wrap .close {
        position: absolute;
        top: 2px;
        right: 2px;
        z-index: 100;
        background-color: #FFF;
        padding: 5px 2px 2px;
        color: #000;
        font-weight: bold;
        cursor: pointer;
        opacity: .2;
        text-align: center;
        font-size: 22px;
        line-height: 10px;
        border-radius: 50%;
    }
    .img-wrap:hover .close {
        opacity: 1;
    }
.picker-input {
    border-width: 0px;
    font: 500 15px Arial, Helvetica;
    color: #000;
    padding: 5px;
    border-style: none;
    border-color: transparent;
    background-color: transparent;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.react-datepicker-popper {
    z-index: 200;
}
.toolbarContainer {
    display: flex;
    align-items: center;
  }
  
.dateLabel {
    color: #999;
    font-size: 15px;
    margin-top: -2px;
    padding-right: 3px;

}

.utilButton {
    margin-left: auto;
}

.rangeButton.MuiButtonBase-root {
    min-width: 30px;
    font-size: 0.9rem;
    line-height: 1;
}

.dateRangeLabel {
    color: #3f51b5;
    font-size: 13px;
    margin-top: -2px;
    font-weight: bold;
}

.rangeToolContainer {
    border: 1px solid rgba(63, 81, 181, 0.5);
    padding: 3px 15px;
    border-radius: 4px;
    display: flex;
    align-items: center;
}
.popOver {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 280px;
    min-height: 100px;
    height: 100%;
    font-size: 12px;
    padding-bottom: 10px;
}

.popToolbar {
    width: 95%;
    margin-left: 10px;
    display: flex;
    align-items: center;
    font-weight: bold;
}

.popContent {
    width: 95%;
    margin-left: 10px;
    margin-top: 5px;
}

.popAddress {
    font-weight: bold;
}

.popStatus {
    margin-top: 15px;
}

.popChips {
    margin-top: 10px;
}

.utilMenuButton {
    margin-left: auto;
}
.utilMenuButton .MuiButton-textSizeSmall {
    padding: 4px 1px;
    min-width: 0px;
}

.workOrderInfo {
    margin-top: 5px;
    margin-left: 10px;
    width: 90%;
}

.iconColor.MuiSvgIcon-root {
    fill: #999;
}

.workOrder {
    display: flex;
    margin-top: 3px;
}

.workDetails {
    margin-left: 5px;
}

.detailTitle {
    color: rgb(150, 148, 148);
    font-size: 11px;
    margin-top: 3px;

}

.detailContent {
    margin-top: 4px;
    color: rgb(39, 38, 38);
}

.technicianLink {
    display: flex;
    justify-content: flex-end;
}

.popperMenu .MuiPaper-root {
    color: #0645AD;
}


.chip {
  margin-right: 8px;
  margin-bottom: 20px;
}

.chip.MuiChip-outlined {
  border: 1px solid #0A6ED1;
  background-color: transparent;
}

.chip.MuiChip-root {
  font-size: .8rem;
  font-weight: 400;
  color: #ffffff;
  border-radius: 4px;
  height: 20px;
}

.chip.MuiChip-clickable.MuiChip-outlined:hover,
.chip.MuiChip-clickable.MuiChip-outlined:focus {
  background-color: #0A6ED1;
  color: #fff;
}


.chip .MuiChip-label {
  padding-left: 8px;
  padding-right: 8px;
}

.yellowchip.MuiChip-root {
  background-color: #F0AB00;
}

.redchip.MuiChip-root {
  background-color: red;
}

.bluechip.MuiChip-root {
  background-color: #0A6ED1;
}

.greenchip.MuiChip-root {
  background-color: green;
}

.infochip.MuiChip-root {
  background-color: #5bc0de;
  color: black;
}


.resourceHeader {
    border-right: none !important;
}

.tableHeader {
    padding-left: 0px !important;
}

.fixedtable {
    table-layout: fixed;
}

.cellStructure {
    min-width: 140px;
    width: 140px;
    max-width: 140px;
    height: 48px;  
    max-height: 48px;    
}

.activeCellStructure {
    min-width: 140px;
    width: 140px;
    max-width: 140px;
    height: 48px;  
    max-height: 48px; 
    padding: 0.5rem 0rem;
}

.orderCellStructure {
    min-width: 168px;
    height: 60px;
}

.draggableRow {
    cursor: pointer;
}

.inDrag {
    border: none;
    display: block;
    margin: 0 auto;
}

.inDrag .orderCellStructure:not(:first-child) {
    display: none;
}

.inDrag .orderCellStructure:first-child {
    border-radius: 4px;
    box-sizing: border-box;
    background-color:#e0e0e0;
    border: 1px solid #868686;
}

.inDrag .orderCellStructure:first-child a.orderLink {
    color: #000;
    margin: 0 auto;
}

.dataCell:not(:last-child) {
    border-right: 2px solid #e5e5e5;
}

a.orderLink {
    text-decoration: none;
}

.statusButton.MuiButtonBase-root {
    vertical-align: top;
    padding: 2px 0px;
    margin: 4px 0 0 0;
}

.minute_00.MuiButtonBase-root {
    margin-left: 0px;
}

.minute_15.MuiButtonBase-root {
    margin-left: 35px;
}

.minute_30.MuiButtonBase-root {
    margin-left: 70px;
}

.minute_45.MuiButtonBase-root {
    margin-left: 105px;
}

.greenStatus.MuiButton-contained {
    background-color: green;
    color: #fff;
    border: 1px solid #006400;
}

.redStatus.MuiButton-contained {
    background-color: red;
    color: #fff;
    border: 1px solid #8B0000;
}

.lgreenStatus.MuiButton-contained {
    background-color: #8cb98c;
    color: #000;
    border: 1px solid #8cb98c;
}

.lredStatus.MuiButton-contained {
    background-color: #e96680;
    color: #000;
    border: 1px solid #e96680;
}

.appContainer {
  display: flex;
  flex-direction: column;
  margin-right: 8px;
}

.componentContainer {
  margin-top: 5px;
  margin-bottom: 10px;
  border: 2px solid #e5e5e5;
}

.orderComponentContainer {
  margin-top: 5px;
  margin-bottom: 10px;
  border: 2px solid #e5e5e5;
}

:focus {
  outline: none;
}
.calendarHeatmap {
  font-family: Helvetica, Arial, sans-serif;
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
.calendarHeatmap .heatmapTooltip {
  pointer-events: none;
  position: absolute;
  z-index: 9999;
  width: 250px;
  max-width: 250px;
  overflow: hidden;
  padding: 15px;
  font-size: 12px;
  text-align: left;
  line-height: 14px;
  color: rgb(51, 51, 51);
  font-family: Helvetica, arial, 'Open Sans', sans-serif;
  background: rgba(255, 255, 255, 0.75);
}
.calendarHeatmap .heatmapTooltip .header strong {
  display: inline-block;
  width: 250px;
}
.calendarHeatmap .heatmapTooltip span {
  display: inline-block;
  width: 50%;
  padding-right: 10px;
  box-sizing: border-box;
}
.calendarHeatmap .heatmapTooltip span,
.calendarHeatmap .heatmapTooltip .header strong {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.formContainer {
    margin-top: 10px;
    margin-bottom: 30px;
    margin-left: 40px;
    width: 90%;
    min-height: 380px;
  }

  .propertyContainer {
      margin-left: 40px;
      display: flex;
      align-items: center;
  }

  .entryPropertyContainer {
    margin-top: 5px;  
    display: flex;
    align-items: center;
  }

  .formContent {
      margin-top: 10px;
      border: 2px solid #ccc;
  }

  .selectFormControl.MuiFormControl-root {
      min-width: 180px;
      margin-left: 80px;
  }

  .selectPropertyFormControl.MuiFormControl-root {
    min-width: 180px;
}

.selectScheduleFormControl.MuiFormControl-root {
    min-width: 46%;
}


  .selectInputLabel.MuiFormLabel-root {
      padding-left: 5px;
  }

  .createField {
      display: flex;
      align-items: center;
      margin: 5px;
      border: 2px solid #e5e5e5;
      padding-left: 10px;
  }

  .propertyEntryField {
    display: flex;
    flex-direction: column;
    margin: 5px;
    padding-left: 10px;
  }

  .enterField {
    display: flex;
    margin: 5px;
    border: 2px solid #757474;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.selectField {
    align-items: baseline;
}

.enterSection {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
}

  .createLabel.MuiFormControl-root {
      margin-top: 5px;
      margin-bottom: 5px;
      min-width: 200px;
  }

  .createItem, .createItem.MuiFormControl-root {  
    margin: 5px;
    margin-left: 80px;
  }

  .requiredItem .MuiFormLabel-root {  
    color: red;
  }

  .enterItem, .enterItem.MuiFormControl-root {  
    margin: 5px;
    min-width: 45%;
  }

    .hidefield.MuiFormControl-root {  
        display: none;
    }

  .actionButton {
    margin-left: auto;
    margin-right: 5px;
}

.sectionPropertiesToolbar {
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.toolbarButton {
    margin-left: 10px;
}

.subLabelContainer {
    margin-left: 10px;
}

.subLabelItem {
    margin-left: 20px;
    display: flex;
    align-items: center;
}

.addButton {
    margin-left: 20px;
}

.sectionTitle {
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.entryunit, .entryField.MuiFormControl-root {
    margin-left: 40px;
}

.sublabelFields {
    margin-left: 40px;
}

.sublabelHeaderContainer {
    display: flex;
    flex-direction: row;
}

.sublabelColumn {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}

.saveButton.MuiButtonBase-root {
    margin-left: 10px;
    margin-top: 10px;
  }




  
