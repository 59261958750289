.resourceHeader {
    border-right: none !important;
}

.tableHeader {
    padding-left: 0px !important;
}

.fixedtable {
    table-layout: fixed;
}

.cellStructure {
    min-width: 140px;
    width: 140px;
    max-width: 140px;
    height: 48px;  
    max-height: 48px;    
}

.activeCellStructure {
    min-width: 140px;
    width: 140px;
    max-width: 140px;
    height: 48px;  
    max-height: 48px; 
    padding: 0.5rem 0rem;
}

.orderCellStructure {
    min-width: 168px;
    height: 60px;
}

.draggableRow {
    cursor: pointer;
}

.inDrag {
    border: none;
    display: block;
    margin: 0 auto;
}

.inDrag .orderCellStructure:not(:first-child) {
    display: none;
}

.inDrag .orderCellStructure:first-child {
    border-radius: 4px;
    box-sizing: border-box;
    background-color:#e0e0e0;
    border: 1px solid #868686;
}

.inDrag .orderCellStructure:first-child a.orderLink {
    color: #000;
    margin: 0 auto;
}

.dataCell:not(:last-child) {
    border-right: 2px solid #e5e5e5;
}

a.orderLink {
    text-decoration: none;
}

.statusButton.MuiButtonBase-root {
    vertical-align: top;
    padding: 2px 0px;
    margin: 4px 0 0 0;
}

.minute_00.MuiButtonBase-root {
    margin-left: 0px;
}

.minute_15.MuiButtonBase-root {
    margin-left: 35px;
}

.minute_30.MuiButtonBase-root {
    margin-left: 70px;
}

.minute_45.MuiButtonBase-root {
    margin-left: 105px;
}

.greenStatus.MuiButton-contained {
    background-color: green;
    color: #fff;
    border: 1px solid #006400;
}

.redStatus.MuiButton-contained {
    background-color: red;
    color: #fff;
    border: 1px solid #8B0000;
}

.lgreenStatus.MuiButton-contained {
    background-color: #8cb98c;
    color: #000;
    border: 1px solid #8cb98c;
}

.lredStatus.MuiButton-contained {
    background-color: #e96680;
    color: #000;
    border: 1px solid #e96680;
}
