.picker-input {
    border-width: 0px;
    font: 500 15px Arial, Helvetica;
    color: #000;
    padding: 5px;
    border-style: none;
    border-color: transparent;
    background-color: transparent;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.react-datepicker-popper {
    z-index: 200;
}