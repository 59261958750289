.popOver {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 280px;
    min-height: 100px;
    height: 100%;
    font-size: 12px;
    padding-bottom: 10px;
}

.popToolbar {
    width: 95%;
    margin-left: 10px;
    display: flex;
    align-items: center;
    font-weight: bold;
}

.popContent {
    width: 95%;
    margin-left: 10px;
    margin-top: 5px;
}

.popAddress {
    font-weight: bold;
}

.popStatus {
    margin-top: 15px;
}

.popChips {
    margin-top: 10px;
}

.utilMenuButton {
    margin-left: auto;
}
.utilMenuButton .MuiButton-textSizeSmall {
    padding: 4px 1px;
    min-width: 0px;
}

.workOrderInfo {
    margin-top: 5px;
    margin-left: 10px;
    width: 90%;
}

.iconColor.MuiSvgIcon-root {
    fill: #999;
}

.workOrder {
    display: flex;
    margin-top: 3px;
}

.workDetails {
    margin-left: 5px;
}

.detailTitle {
    color: rgb(150, 148, 148);
    font-size: 11px;
    margin-top: 3px;

}

.detailContent {
    margin-top: 4px;
    color: rgb(39, 38, 38);
}

.technicianLink {
    display: flex;
    justify-content: flex-end;
}

.popperMenu .MuiPaper-root {
    color: #0645AD;
}

