.toolbarContainer {
    display: flex;
    align-items: center;
  }
  
.dateLabel {
    color: #999;
    font-size: 15px;
    margin-top: -2px;
    padding-right: 3px;

}

.utilButton {
    margin-left: auto;
}

.rangeButton.MuiButtonBase-root {
    min-width: 30px;
    font-size: 0.9rem;
    line-height: 1;
}

.dateRangeLabel {
    color: #3f51b5;
    font-size: 13px;
    margin-top: -2px;
    font-weight: bold;
}

.rangeToolContainer {
    border: 1px solid rgba(63, 81, 181, 0.5);
    padding: 3px 15px;
    border-radius: 4px;
    display: flex;
    align-items: center;
}