.appContainer {
  display: flex;
  flex-direction: column;
  margin-right: 8px;
}

.componentContainer {
  margin-top: 5px;
  margin-bottom: 10px;
  border: 2px solid #e5e5e5;
}

.orderComponentContainer {
  margin-top: 5px;
  margin-bottom: 10px;
  border: 2px solid #e5e5e5;
}

:focus {
  outline: none;
}